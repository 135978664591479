import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { FC } from 'react'
import { uppercaseCountryLocale } from 'utils/locale-helpers'
import Layout from '../components/Layout'
import { FooterData, NavigationHeader, TopBanner } from '../context/LinkContext'

import type { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box, Container, Text, Heading } from 'atomic'
import Image from 'next/image'
import { getFooterData, getNavHeaders, getTopBanners } from 'utils/cms-helper'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  navHeaders?: NavigationHeader[]
  topBanners?: TopBanner[]
  footerData?: FooterData
}

const Custom404: FC<PropTypes> = ({ navHeaders, topBanners, footerData }) => {
  const { t } = useSafeTranslation()
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Head>

      <Layout navHeaders={navHeaders} topBanners={topBanners} footerData={footerData} noIndex>
        <Container display="flex" justifyContent="center" alignItems="center" marginTop="xl">
          <Box maxWidth={871} textAlign="center">
            <Image
              src="/images/404-alpha-800x800.gif"
              alt="not found"
              width={400}
              height={400}
              sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
              priority={true}
              fetchPriority="high"
            />
            <Heading as="h1" marginTop="xl" textAlign="center" fontSize="xxxl">
              {t('not_found_title')}
            </Heading>
            <Text textAlign="center" marginTop="l" marginBottom="xxxl">
              {t('not_found_text')}
            </Text>
          </Box>
        </Container>
      </Layout>
    </>
  )
}

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<PropTypes>> => {
  const devEnvironment = process.env.NODE_ENV === 'development'
  const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  const locale = context.locale

  const params = {
    version: devEnvironment ? 'draft' : 'published',
    cv: null,
    resolve_links: 'url',
  }

  if (context.preview) {
    params.version = 'draft'
    params.cv = Date.now()
  }

  const builderCache = prodEnvironment ? {} : { cacheSeconds: 0, staleCacheSeconds: 0 }
  const navHeaders = await getNavHeaders(builderCache, locale)
  const topBanners = await getTopBanners(builderCache, locale)
  const footerData = await getFooterData(builderCache, locale)

  return {
    props: {
      navHeaders: navHeaders ?? null,
      topBanners: topBanners ?? null,
      footerData: footerData ?? null,
      ...(await serverSideTranslations(uppercaseCountryLocale(locale), ['common'])),
    },
  }
}

export default Custom404
